@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('assets/css/landing.css');

* {
  @apply
    transition-all
    duration-300;
}

footer {
  @apply
    text-gray-800
    bg-gray-200
    dark:text-gray-300
    dark:bg-gray-800;
}

.text-styles {
  @apply
    text-gray-800
    dark:text-gray-400
    transition-all
    duration-100;
}

.text-styles-hover {
  @apply
    hover:text-gray-900
    dark:hover:text-gray-200;
}

.title-styles {
  @apply
    text-sm
    dark:text-gray-600
    tracking-widest
    transition-all
    duration-100;
}
