.nav-container {
    @apply
        md:ml-auto
        md:mr-auto
        flex
        flex-wrap
        items-center
        text-base
        justify-center;
}

.nav-item {
    @apply
        uppercase
        text-sm
        text-white
        dark:text-gray-100
        hover:border-b-2
        hover:text-white
        hover:border-primary-dark
        hover:font-bold
        transition-all
        duration-100;
}

.active-item {
    @apply
        border-b-2
        border-primary-500
        dark:border-primary-dark
        font-bold
}

.pricing-container {
    @apply
        p-4
        xl:w-1/4
        md:w-1/2
        w-full;
}

.pricing-content {
    @apply
        h-full
        p-6
        rounded-lg
        border-2
        flex
        flex-col
        relative
        overflow-hidden
        shadow-md;
}

.pricing-still-icon {
    @apply
        w-4
        h-4
        mr-2
        inline-flex
        items-center
        justify-center
        bg-gray-400
        text-white
        rounded-full
        flex-shrink-0;
}

.pricing-button{ 
    @apply
        flex
        items-center
        mt-auto
        text-white
        bg-gray-400
        border-0
        py-2
        px-4
        w-full
        focus:outline-none
        hover:bg-gray-500
        rounded
        uppercase
        text-sm;
}

.pricing-popular-label {
    @apply
        bg-gray-500
        dark:bg-gray-100
        text-white
        dark:text-gray-800
        px-3
        py-1
        tracking-widest
        text-xs
        absolute
        right-0
        top-0
        rounded-bl;
}

.pricing-price-container {
    @apply
        text-5xl
        leading-none
        flex
        items-center
        pb-4
        mb-4
        border-b
        border-gray-200
        dark:text-gray-200;
}

.pricing-hover>.pricing-content-popular{
    @apply
        dark:border-primary-500;
}

.pricing-hover>.pricing-content{
    @apply
        border-primary-500
        dark:border-primary-500
        shadow-xl;
}

.pricing-hover>.pricing-content{
    @apply
        border-primary-500
        dark:border-primary-dark;
}

.pricing-hover .pricing-still-icon,
.pricing-hover .pricing-button {
    @apply
        bg-primary-500
        dark:bg-primary-dark;
}

.pricing-hover .pricing-popular-label {
    @apply
        bg-primary-500
        dark:bg-primary-dark
        dark:text-white;
}

.pricing-price {
    @apply
        text-gray-800
        dark:text-gray-300;
}

.pricing-hover .pricing-price {
    @apply
        text-primary-500
        dark:text-primary-dark;
}

.pricing-hover .pricing-price-container {
    @apply
        border-primary-500
        dark:border-primary-dark;
}

.landing-title {
    @apply
        sm:text-4xl
        text-3xl
        font-medium
        mb-2
        text-gray-800
        dark:text-gray-200;
}

.landing-subtitle {
    @apply
        lg:w-2/3
        mx-auto
        leading-relaxed
        text-base
        text-gray-400;
}

#home:before {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    background: url(../images/landing/wave.svg);
    background-size: cover;
    background-repeat: no-repeat;
}

.dark #home:before {
    background: url(../images/landing/wave-dark.svg);
    background-size: cover;
    background-repeat: no-repeat;
}

#home div {
    z-index: 1;
}

.img-background {
    background: url(../images/landing/background.jpg);
    background-size: cover;
    background-repeat: no-repeat;
}